import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import Popover from "@material-ui/core/Popover";
import Button from "@material-ui/core/Button";

//  for Chexmix and Subsector tabs
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

const styles = (theme) => ({
  card: {
    maxWidth: 1100,
  },
  paper: {
    height: 200,
    width: 200,
    padding: 20,
  },
  leftSection: {},
  rightSection: {},
  featureHeatmap: {
    width: 210,
    height: 530,
    marginTop: 35,
  },
  colorBar: {
    height: 470,
    width: 20,
    marginTop: 14,
    marginLeft: -25,
  },
  enrichedcolorBar: {
    height: 443,
    width: 20,
    marginTop: 11,
    marginLeft: -12.5,
  },
  mainContainer: {
    overflow: "scroll",
  },
  composite: {
    height: 135,
    marginLeft: -6,
    marginBottom: -8,
    marginTop: 25,
  },
  regionHeatmap: {
    height: 410,
  },
  sectionTitle: {
    fontSize: 18,
  },
  scroller: {
    overflow: "hidden",
  },
  chexmixContent: {
    border: "2px solid red",
  },
  rp: {
    height: 20,
    width: 20,
    background: "#ff2600",
    border: `1px solid black`,
  },
  stm: {
    height: 20,
    width: 20,
    background: "#d22bd7",
    border: `1px solid black`,
  },
  ess: {
    height: 20,
    width: 20,
    background: "#0096ff",
    border: `1px solid black`,
  },
  cof: {
    height: 20,
    width: 20,
    background: "#6a77a7",
    border: `1px solid black`,
  },
  unb: {
    height: 20,
    width: 20,
    background: "#000000",
    border: `1px solid black`,
  },
  trnaprox: {
    height: 20,
    width: 20,
    background: "#000000",
    border: `1px solid black`,
  },
});

// # RP, STM, TFO, UNB, NoPic, trnaprox, ChexMix_extreme, blacklist
// mycolors = ['#ff2600','#d22bd7','#0096ff','#6a77a7','#000000','#000000','#000000','#000000']

class CodingSection extends React.Component {
  // value is used to keep the tab active
  state = {
    selectedTab: 0,
    imageUrl: this.props.images,
    anchorEl: null,
  };

  // Used to update the CodingSection , when new replicate tab is selected
  componentDidUpdate(prevProps) {
    if (prevProps.images !== this.props.images) {
      this.setState({
        selectedTab: 0,
        imageUrl: this.props.images,
      });
    }
  }

  // Should be another way to do this , but for now this works
  // How about sending the props themselves in the compressed way so that these prop changes can be made easy.
  handleChange = (event, selectedTab) => {
    selectedTab === 0
      ? this.setState({
          selectedTab: selectedTab,
          imageUrl: this.props.images,
        })
      : this.setState({
          selectedTab: selectedTab,
          imageUrl: {
            allFeaturesHeatmap: this.props.images.allFeaturesHeatmap,
            allFeaturesColorbar: this.props.images.allFeaturesColorbar,
            boundFeaturesHeatmap: this.props.images.enrichedFeaturesHeatmap,
            boundFeaturesColorbar: this.props.images.enrichedFeaturesColorbar,
            promoterComposite: this.props.images.promoterEnrichedComposite,
            promoterHeatmap: this.props.images.promoterEnrichedHeatmap,
            tssComposite: this.props.images.tssEnrichedComposite,
            tssHeatmap: this.props.images.tssEnrichedHeatmap,
            tesHeatmap: this.props.images.tesEnrichedHeatmap,
            tesComposite: this.props.images.tesEnrichedComposite,
          },
        });
  };

  handleClick = (event) => {
    this.setState({
      anchorEl: event.currentTarget,
    });
  };

  handleClose = () => {
    this.setState({
      anchorEl: null,
    });
  };

  render() {
    const { classes } = this.props;
    const { imageUrl } = this.state;
    const { selectedTab } = this.state;
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);

    return (
      <div className={classes.card}>
        <Popover
          id="simple-popper"
          open={open}
          anchorEl={anchorEl}
          onClose={this.handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
        >
          <Paper>
            <CardContent>
              <Typography variant="subtitle1" gutterBottom>
                Gene Categories{" "}
              </Typography>
              <br />
              <Grid
                container
                spacing={2}
                direction="column"
                wrap="wrap"
                justify="flex-start"
              >
                <Grid item>
                  <Grid
                    container
                    spacing={1}
                    direction="row"
                    wrap="nowrap"
                    justify="flex-start"
                  >
                    <Grid item>
                      <div className={classes.rp} />
                    </Grid>
                    <Grid item>
                      <Typography gutterBottom>
                        Ribosomal protein genes
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item>
                  <Grid
                    container
                    spacing={1}
                    direction="row"
                    wrap="nowrap"
                    justify="flex-start"
                  >
                    <Grid item>
                      <div className={classes.stm} />
                    </Grid>
                    <Grid item>
                      <Typography gutterBottom>
                        SAGA / Tup1 / Mediator-bound genes
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item>
                  <Grid
                    container
                    spacing={1}
                    direction="row"
                    wrap="nowrap"
                    justify="flex-start"
                  >
                    <Grid item>
                      <div className={classes.ess} />
                    </Grid>
                    <Grid item>
                      <Typography gutterBottom>
                        {" "}
                        Transcription Factor Organized genes{" "}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item>
                  <Grid
                    container
                    spacing={1}
                    direction="row"
                    wrap="nowrap"
                    justify="flex-start"
                  >
                    <Grid item>
                      <div className={classes.cof} />
                    </Grid>
                    <Grid item>
                      <Typography gutterBottom> Unbound genes </Typography>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item>
                  <Grid
                    container
                    spacing={1}
                    direction="row"
                    wrap="nowrap"
                    justify="flex-start"
                  >
                    <Grid item>
                      <div className={classes.unb} />
                    </Grid>
                    <Grid item>
                      <Typography gutterBottom>Blacklisted genes</Typography>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item>
                  <Grid
                    container
                    spacing={1}
                    direction="row"
                    wrap="nowrap"
                    justify="flex-start"
                  ></Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Paper>
        </Popover>

        <Paper>
          {/* overriding the css using the css api for the tabs, 
        removed the scrollbar that appears otherwise 
        */}

          <Tabs
            value={selectedTab}
            onChange={this.handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="on"
            classes={{ scrollable: classes.scroller }}
          >
            <Tab label="Chexmix" key={0} />
            <Tab label="Subsector" key={1} />
          </Tabs>

          <Divider />

          <CardContent className={classes.sectionHolder}>
            <Typography component="div">
              <Button
                size="small"
                color="primary"
                style={{ float: "right", marginBottom: 0 }}
                onClick={this.handleClick}
              >
                Legend
              </Button>

              {/* Chexmix Heatmap Section */}
              <Grid
                container
                spacing={2}
                direction="row"
                wrap="nowrap"
                justify="flex-start"
                className={classes.mainContainer}
              >
                {/* Buffer Section */}
                <Grid item />

                {/* Left Section */}
                <Grid item className={classes.leftSection}>
                  <Grid
                    container
                    direction="row"
                    justify="space-evenly"
                    alignItems="center"
                    spacing={1}
                    wrap="nowrap"
                  >
                    <Grid item>
                      <img
                        src={imageUrl.allFeaturesHeatmap}
                        alt="All Features Heatmap"
                        className={classes.featureHeatmap}
                      />
                    </Grid>
                    <Grid item>
                      <img
                        src={imageUrl.allFeaturesColorbar}
                        alt="Bound Features Colorbar"
                        className={classes.colorBar}
                      />
                    </Grid>
                    <Grid item />
                    <Grid item>
                      <img
                        src={imageUrl.boundFeaturesHeatmap}
                        alt="Bound Features Heatmap"
                        className={classes.featureHeatmap}
                      />
                    </Grid>
                    <Grid item>
                      <img
                        src={imageUrl.boundFeaturesColorbar}
                        alt="Bound Features Colorbar"
                        className={classes.colorBar}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                {/* Buffer Section */}
                <Grid item />

                {/* Right Section */}
                <Grid item className={classes.rightSection}>
                  <Grid
                    container
                    direction="row"
                    justify="space-evenly"
                    alignItems="center"
                    spacing={0}
                    wrap="nowrap"
                  >
                    {/* Promoter */}
                    <Grid item>
                      <Grid
                        container
                        direction="column"
                        justify="flex-start"
                        alignItems="center"
                        spacing={0}
                      >
                        <Grid item>
                          <img
                            src={imageUrl.promoterComposite}
                            alt="Promoter Composite"
                            className={classes.composite}
                          />
                        </Grid>
                        <Grid item>
                          <img
                            src={imageUrl.promoterHeatmap}
                            alt="Promoter Heatmap"
                            className={classes.regionHeatmap}
                          />
                        </Grid>
                      </Grid>
                    </Grid>

                    {/* TSS */}
                    <Grid item>
                      <Grid
                        container
                        direction="column"
                        justify="flex-start"
                        alignItems="center"
                        spacing={0}
                      >
                        <Grid item>
                          <img
                            src={imageUrl.tssComposite}
                            alt="TSS Composite"
                            className={classes.composite}
                          />
                        </Grid>
                        <Grid item>
                          <img
                            src={imageUrl.tssHeatmap}
                            alt="TSS Heatmap"
                            className={classes.regionHeatmap}
                          />
                        </Grid>
                      </Grid>
                    </Grid>

                    {/* TES */}
                    <Grid item>
                      <Grid
                        container
                        direction="column"
                        justify="flex-start"
                        alignItems="center"
                        spacing={0}
                      >
                        <Grid item>
                          <img
                            src={imageUrl.tesComposite}
                            alt="TES Composite"
                            className={classes.composite}
                          />
                        </Grid>
                        <Grid item>
                          <img
                            src={imageUrl.tesHeatmap}
                            alt="TES Heatmap"
                            className={classes.regionHeatmap}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Typography>
          </CardContent>
        </Paper>
      </div>
    );
  }
}

CodingSection.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CodingSection);
