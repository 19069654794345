import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";

const styles = {
  rp: {
    height: 20,
    width: 20,
    background: "#ff2600",
    border: `1px solid black`,
  },
  stm: {
    height: 20,
    width: 20,
    background: "#d22bd7",
    border: `1px solid black`,
  },
  ess: {
    height: 20,
    width: 20,
    background: "#0096ff",
    border: `1px solid black`,
  },
  cof: {
    height: 20,
    width: 20,
    background: "#6a77a7",
    border: `1px solid black`,
  },
  unb: {
    height: 20,
    width: 20,
    background: "#000000",
    border: `1px solid black`,
  },
  trnaprox: {
    height: 20,
    width: 20,
    background: "#000000",
    border: `1px solid black`,
  },
};

class Legend extends React.Component {
  // value is used to keep the tab active
  state = {};

  render() {
    const { classes } = this.props;

    return (
      <Paper>
        <CardContent>
          <Typography component="span" variant="subtitle1" gutterBottom>
            Gene Categories{" "}
          </Typography>
          <br />
          <Grid
            container
            spacing={2}
            direction="column"
            wrap="wrap"
            justify="flex-start"
          >
            <Grid item>
              <Grid
                container
                spacing={1}
                direction="row"
                wrap="nowrap"
                justify="flex-start"
              >
                <Grid item>
                  <span className={classes.rp} />
                </Grid>
                <Grid item>
                  <Typography component="span" gutterBottom>
                    Ribosomal protein genes
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item>
              <Grid
                container
                spacing={1}
                direction="row"
                wrap="nowrap"
                justify="flex-start"
              >
                <Grid item>
                  <span className={classes.stm} />
                </Grid>
                <Grid item>
                  <Typography component="span" gutterBottom>
                    SAGA/Tup1/Mediator-bound genes
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item>
              <Grid
                container
                spacing={1}
                direction="row"
                wrap="nowrap"
                justify="flex-start"
              >
                <Grid item>
                  <span className={classes.ess} />
                </Grid>
                <Grid item>
                  <Typography component="span" gutterBottom>
                    Transcription Factor Organized genes
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item>
              <Grid
                container
                spacing={1}
                direction="row"
                wrap="nowrap"
                justify="flex-start"
              >
                <Grid item>
                  <span className={classes.cof} />
                </Grid>
                <Grid item>
                  <Typography component="span" gutterBottom>
                    Unbound genes
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item>
              <Grid
                container
                spacing={1}
                direction="row"
                wrap="nowrap"
                justify="flex-start"
              >
                <Grid item>
                  <span className={classes.unb} />
                </Grid>
                <Grid item>
                  <Typography component="span" gutterBottom>
                    Blacklisted genes
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item>
              <Grid
                container
                spacing={1}
                direction="row"
                wrap="nowrap"
                justify="flex-start"
              ></Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Paper>
    );
  }
}

Legend.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Legend);
