import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import Badge from "@material-ui/core/Badge";
import CardContent from "@material-ui/core/CardContent";

// additional components
import CodingSection from "./CodingSection";
import OtherFeaturesSection from "./OtherFeaturesSection";
import MotifSection from "./MotifSection";
import TranscribedNonCoding from "./TranscribedNonCoding";
import { Grid } from "@material-ui/core";

// retrieve app configuration settings
import Config from "../Config";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    // width: "100%"
  },
  scroller: {
    overflow: "hidden",
  },
  card: {
    marginTop: 20,
  },
  treatmentBadge: {
    marginRight: 20,
  },
  sectionHolder: {
    background: "#fafafa",
  },
  contentHolder: {
    //  setting the padding based on the screen size
    [theme.breakpoints.down("sm")]: {
      // backgroundColor: theme.palette.secondary.main,
      padding: 8,
    },
    [theme.breakpoints.up("md")]: {
      // backgroundColor: theme.palette.primary.main,
      padding: 8 * 3,
    },
    [theme.breakpoints.up("lg")]: {
      // backgroundColor: "green",
      padding: 8 * 3,
    },
  },
  removeCart: {
    color: "green",
  },
});

class ReplicateTabs extends React.Component {
  // selected Replicate
  state = {
    replicate: 0,
    samples: this.props.samples[0],
  };

  handleChange = (event, replicate) => {
    this.setState({
      replicate: replicate,
      samples: this.props.samples[replicate],
    });
  };

  componentDidMount() {
    this.setState({
      replicate: 0,
      samples: this.props.samples[0],
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.samples !== this.props.samples) {
      this.setState({
        replicate: 0,
        samples: this.props.samples[0],
      });
    }
  }

  render() {
    const { classes } = this.props;
    const { replicate } = this.state;

    let i = 0;
    let tabList = this.props.samples.map((sample) => {
      // Setting the badge based on Whether it is heatshock or YPD or Other
      let badge = "";
      switch (sample.treatments) {
        case "Normal":
          badge = (
            <Badge
              color="primary"
              className={classes.treatmentBadge}
              badgeContent={" "}
            />
          );

          break;
        case "Heat Shock":
          badge = (
            <Badge
              color="secondary"
              className={classes.treatmentBadge}
              badgeContent={" "}
            />
          );

          break;
        case "H2O2 / 180min":
        case "H2O2 / 6min":
        case "H2O2 / 30min":
          badge = (
            <Badge
              style={{
                background: "#388e3c",
                padding: 11,
                borderRadius: 20,
                marginRight: 10,
              }}
              badgeContent={" "}
            />
          );
          break;

        default:
          badge = (
            <Badge
              style={{
                background: "#9c27b0",
                padding: 11,
                borderRadius: 20,
                marginRight: 10,
              }}
              badgeContent={" "}
            />
          );
          break;
      }

      i = i + 1;
      let tabname =
        Config.settings.siteAvailability === "private" ? (
          <Typography variant="body1">
            {badge}
            {sample.sampleId}
          </Typography>
        ) : (
          <Typography>
            {badge}
            {/* {"Sample " + i.toString()} */}
            {sample.sampleId}
          </Typography>
        );

      return <Tab label={tabname} key={i} />;
    });

    return (
      <div className={classes.root}>
        <Paper className={classes.card}>
          {/* overriding the css using the css api for the tabs, 
          removed the scrollbar that appears otherwise 
          */}

          <Tabs
            value={replicate}
            onChange={this.handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="on"
            classes={{ scrollable: classes.scroller }}
          >
            {tabList}
          </Tabs>

          <Divider />

          {/* Main Content */}
          <CardContent className={classes.sectionHolder}>
            {/* Sections */}
            <Typography component="div" className={classes.contentHolder}>
              <Grid
                container
                spacing={3}
                direction="column"
                wrap="nowrap"
                justify="flex-start"
                className={classes.mainContainer}
              >
                {/* Subsections for each replicate */}
                <Grid item>
                  <span id="section1" />
                  <CodingSection images={this.state.samples.codingImages[0]} />
                </Grid>
                <Grid item>
                  <span id="section2" />
                  <TranscribedNonCoding
                    images={this.state.samples.nonCodingImages[0]}
                  />
                </Grid>
                <Grid item>
                  <span id="section3" />
                  <OtherFeaturesSection
                    images={this.state.samples.nonCodingImages[0]}
                  />
                </Grid>
                <Grid item>
                  <span id="section4" />
                  <MotifSection
                    images={this.state.samples.motifImages[0]}
                    motifCount={this.state.samples.motifCount}
                  />
                </Grid>
              </Grid>
            </Typography>
          </CardContent>
        </Paper>
      </div>
    );
  }
}

ReplicateTabs.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ReplicateTabs);
