import React, { Component } from "react";
import axios from "axios";
import { BrowserRouter, Route, Switch } from "react-router-dom";

// Material ui styling
import { withStyles } from "@material-ui/core/styles";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";

// SubComponents
import EditSample from "./Components/EditSample";
import Sample from "./Components/Sample";
import Navbar from "./Components/Navbar";
import ExploreGrid from "./Components/ExploreGrid";
import SimpleLanding from "./Components/SimpleLanding";
import LinearProgress from "@material-ui/core/LinearProgress";
import Help from "./Components/Help";
import AboutUs from "./Components/AboutUs";

// previous landing pages
// import LandingPage from "./Components/LandingPage";
// import SplitLandingPage from "./Components/SplitLandingPage";

// cartDialog components
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import RemoveCartIcon from "@material-ui/icons/RemoveShoppingCart";
import DownloadIcon from "@material-ui/icons/GetApp";
import Divider from "@material-ui/core/Divider";
import CardContent from "@material-ui/core/CardContent";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";

// Configuration
import Config from "./Config";

// Importing the contextAPI
import { DataProvider } from "./Components/DataContext";

// creating a theme with default fontfamily
const theme1 = createMuiTheme({
  typography: {
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '"Roboto Slab"',
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ],
    useNextVariants: true,
  },
});

// creating custom theme
const theme2 = createMuiTheme({
  palette: {
    primary: {
      light: "#60ad5e",
      main: "#2e7d32",
      dark: "#005005",
      contrastText: "#fff",
    },
    secondary: {
      light: "#ff7961",
      main: "#f44336",
      dark: "#ba000d",
      contrastText: "#fff",
    },
  },
  typography: {
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '"Bree Serif"',
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ],
    useNextVariants: true,
  },
});

const styles = (theme) => ({
  list: {
    width: 375,
  },
  appBar: {
    background: "#eeeeee",
  },
  appBar2: {
    background: "#fff",
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: "0 8px",
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
    height: 75,
  },
  progress: {
    width: 500,
  },
  textField: {
    width: 200,
    margin: "auto",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  tableHeader: {
    backgroundColor: "#e8eaf6",
    fontSize: 15,
  },
  borderLine: {
    border: `1px solid ${theme.palette.divider}`,
    color: "black",
    fontSize: 15,
  },
});

class App extends Component {
  state = {
    isThemeLight: true,
    searchOptions: [],
    data: null,
    cartItems: [],
    cartDialog: false,
  };

  handleThemeChange = () => {
    this.setState({ isThemeLight: !this.state.isThemeLight });
  };

  componentDidMount() {
    const aliasURL =
      Config.settings.apiURL +
      Config.settings.aliasesEndpoint +
      "/" +
      Config.settings.siteAvailability;

    axios
      .get(Config.settings.apiURL + Config.settings.samplesEndpoint)
      .then((res) => {
        this.setState({ data: res.data.samples });
      });

    // fetch aliases
    axios
      .get(aliasURL)
      .then((res) => {
        this.setState({
          searchOptions: res.data.aliases,
        });
      })
      .catch((err) => {
        console.log(err);
      });

    // Comment below lines while in development,
    // Alerts the user about resetting values stored on the website.
    // window.onbeforeunload = function() {
    //   return "User Reloaded the page !";
    // }
  }

  // Download cart functions
  addToCart = (sample) => (event) => {
    // Check if the sampleId already exists
    let cart = this.state.cartItems.map((item) => {
      return item.sampleId;
    });

    if (!cart.includes(sample.sampleId)) {
      let items = [...this.state.cartItems, sample];
      this.setState({
        cartItems: items,
      });
    }
  };

  removeFromCart = (sample) => (event) => {
    let items = this.state.cartItems.filter((item) => {
      return item.sampleId !== sample.sampleId;
    });

    this.setState({
      cartItems: items,
    });
  };

  clearCart = () => {
    this.setState({
      cartItems: [],
      cartDialog: false,
    });
  };

  openCartDialog = () => {
    this.setState({ cartDialog: true });
  };

  closeCartDialog = () => {
    this.setState({ cartDialog: false });
  };

  handleDownload = () => {
    // reference to create a text file using javascript on the front-end
    // https://stackoverflow.com/questions/44656610/download-a-string-as-txt-file-in-react/44661948#44661948

    // creating the content for the batchDownload
    let content = this.state.cartItems.map((item) => {
      let outfileName = item.downloadLink.split("/");
      let string =
        "url=" +
        item.downloadLink +
        "\noutput=" +
        outfileName[outfileName.length - 1] +
        "\n";
      return string;
    });

    // creating the text file with appropriat urls and initiate download
    const element = document.createElement("a");
    const file = new Blob(content, { type: "text/plain" });
    element.href = URL.createObjectURL(file);
    element.download = "yepBatchDownload.txt";
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };

  render() {
    const { isThemeLight } = this.state;
    const { classes } = this.props;

    const background = isThemeLight
      ? "linear-gradient(to bottom,#e8eaf6,#e8eaf6)"
      : "linear-gradient(to bottom,#e8f5e9,#e8f5e9)";

    // set the body color to the theme.
    document.body.style.background = background;

    return (
      <MuiThemeProvider theme={isThemeLight ? theme1 : theme2}>
        <CssBaseline />
        <div>
          <BrowserRouter basename="/yep">
            {this.state.data ? (
              <DataProvider
                value={{
                  data: this.state.data,
                  searchOptions: this.state.searchOptions,
                  openCartDialog: this.openCartDialog,
                  closeCartDialog: this.closeCartDialog,
                  cartItems: this.state.cartItems,
                  addToCart: this.addToCart,
                  removeFromCart: this.removeFromCart,
                }}
              >
                <Navbar
                  searchOptions={this.state.searchOptions}
                  handleThemeChange={this.handleThemeChange}
                />
                <Switch>
                  {/* <Route exact path="/" component={SplitLandingPage} /> */}
                  {/* <Route exact path="/" component={LandingPage} /> */}
                  <Route exact path="/" component={SimpleLanding} />
                  <Route
                    exact
                    path="/factor/:protein_name"
                    component={Sample}
                  />
                  <Route exact path="/aboutus/" component={AboutUs} />
                  <Route exact path="/help/" component={Help} />
                  <Route exact path="/explore/" component={ExploreGrid} />
                  <Route path="/edit/:sample_id" component={EditSample} />
                </Switch>
              </DataProvider>
            ) : (
              <LinearProgress />
            )}
          </BrowserRouter>
          {/* Dialog that shows up for cart. 
          To extend this feature, you need to create a route
          & render a component with extensive features. */}
          <Dialog
            open={this.state.cartDialog}
            onClose={this.closeCartDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth={"md"}
          >
            <DialogTitle id="alert-dialog-title">
              {"Using batch download"}
              <IconButton
                aria-label="Close"
                className={classes.closeButton}
                onClick={this.closeCartDialog}
              >
                <CloseIcon color="primary" />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Click the “Download” button below to download a
                "yepBatchDownload.txt” file that contains a list of URLs to
                selected sample datasets.
                <em>
                  {" "}
                  Below is the command to start the batch download using
                  "yepBatchDownload.txt"
                </em>
                <br />
                <br />
                <strong>curl -k -K yepBatchDownload.txt</strong>
              </DialogContentText>
              <br />
              <Divider />

              {/* Cart Items in a table format */}
              <CardContent>
                <Table className={classes.table}>
                  <TableHead className={classes.tableHeader}>
                    <TableRow className={classes.borderLine}>
                      <TableCell align="left" className={classes.borderLine}>
                        Protein Name
                      </TableCell>
                      <TableCell align="left" className={classes.borderLine}>
                        Sample ID
                      </TableCell>
                      <TableCell align="left" className={classes.borderLine}>
                        Assay
                      </TableCell>
                      <TableCell align="left" className={classes.borderLine}>
                        Condition
                      </TableCell>
                      <TableCell align="left" className={classes.borderLine}>
                        Remove Item
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.cartItems.map((item) => {
                      return (
                        <TableRow key={item._id} className={classes.borderLine}>
                          <TableCell
                            align="left"
                            className={classes.borderLine}
                          >
                            {item.standardGeneName}
                          </TableCell>
                          <TableCell
                            align="left"
                            className={classes.borderLine}
                          >
                            {item.sampleId}
                          </TableCell>
                          <TableCell
                            align="left"
                            className={classes.borderLine}
                          >
                            {item.assayType}
                          </TableCell>
                          <TableCell
                            align="left"
                            className={classes.borderLine}
                          >
                            {item.growthMedia} / {item.treatments}
                          </TableCell>
                          <TableCell
                            align="left"
                            className={classes.borderLine}
                          >
                            <RemoveCartIcon
                              fontSize="small"
                              color="secondary"
                              onClick={this.removeFromCart(item)}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </CardContent>
            </DialogContent>

            <DialogActions>
              <Button size="small" onClick={this.clearCart} color="secondary">
                Clear All{" "}
                <RemoveCartIcon fontSize="small" style={{ marginLeft: 6 }} />
              </Button>
              <Button
                size="small"
                onClick={this.handleDownload}
                color="primary"
              >
                Download{" "}
                <DownloadIcon fontSize="small" style={{ marginLeft: 6 }} />
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </MuiThemeProvider>
    );
  }
}

export default withStyles(styles)(App);
