import React, { Component } from "react";
import axios from "axios";

// material-ui imports
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import LaunchIcon from "@material-ui/icons/Launch";
import TrackHubIcon from "@material-ui/icons/AssessmentOutlined";
import InfoIcon from "@material-ui/icons/InfoOutlined";
import Drawer from "@material-ui/core/Drawer";
import LinearProgress from "@material-ui/core/LinearProgress";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Tooltip from "@material-ui/core/Tooltip";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";

// For Sample Meta information Drawer
import ReplicateTabs from "./ReplicateTabs";
import ScrollSpy from "./ScrollSpy";
import SampleStatsTable from "./SampleStatsTable";
import TrackLoader from "./TrackLoader";
// import Scrollspy from "react-scrollspy";

// For SampleStats Notifications
import { SnackbarProvider } from "notistack";

// retrieve app configuration settings
import Config from "../Config";

const styles = (theme) => ({
  center: {
    margin: "0 auto",
    width: 1200,
    padding: 10,
  },
  leftSection: {
    // maxWidth: 1200
    // border: "2px solid green"
  },
  card: {
    maxWidth: 1200,
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  searchbar: {
    width: 1100,
  },
  scrollSpy: {
    position: "fixed",
  },
  textField: {
    marginLeft: 10,
    width: 160,
  },
  filterContainer: {
    marginLeft: 60,
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

class Sample extends Component {
  state = {
    post: null, // setting it to one sample
    proteinName: null,
    pageData: null,
    drawer: false,
    samples: null,
    loading: true,
    treatments: [],
    treatment: "",
    antibodies: [],
    antibody: "",
    message: "Fetching Samples",
    trackLoader: false,
  };

  loadData = (id) => () => {
    let dataURL =
      Config.settings.apiURL + Config.settings.samplesEndpoint + "/" + id;
    // console.log(dataURL);

    axios
      .get(dataURL)
      .then((res) => {
        const treatments = [
          ...new Set(
            res.data.samples.map((sample) => {
              return sample.treatments;
            })
          ),
        ];

        const antibodies = [
          ...new Set(
            res.data.samples.map((sample) => {
              return sample.antibody;
            })
          ),
        ];

        this.setState({
          post: res.data.samples[0],
          proteinName: id,
          samples: res.data.samples,
          pageData: res.data.samples,
          treatments: [...treatments, "All"],
          treatment: "",
          antibodies: [...antibodies],
          antibody: "",
          loading: false,
        });
      })
      .catch((error) => {
        console.log(error);
      });

    // Setting the title of the browser tab
    document.title = id.toUpperCase() + " | YEP";
  };

  // you would access the route parameter here and then maybe fetch stuff
  componentDidMount() {
    // console.log(this.props);
    let id = this.props.match.params.protein_name;
    this.loadData(id)();
  }

  // Checks if the props have changed for the page and re-fetches the data.
  componentDidUpdate(prevProps) {
    if (
      prevProps.match.params.protein_name !==
      this.props.match.params.protein_name
    ) {
      // console.log(this.props.match.params.protein_name);
      let id = this.props.match.params.protein_name;
      this.loadData(id)();
    }
  }

  //  function to use when you have multiple filters on the same pageData.
  filterChange = (key, value, proteinName) => {
    var query = {};
    var result = "";

    // function that goes into the filter()
    function searchFunc(data) {
      return Object.keys(this).every((key) => data[key] === this[key]);
    }

    switch (key) {
      case "antibody":
        query =
          this.state.treatment !== ""
            ? {
                antibody: value,
                proteinName: proteinName,
                treatments: this.state.treatment,
              }
            : { antibody: value, proteinName: proteinName };
        result = this.state.samples.filter(searchFunc, query);
        // console.log(result);
        break;

      case "treatment":
        query = { treatments: value, proteinName: proteinName };
        result = this.state.samples.filter(searchFunc, query);
        // console.log(result);
        break;

      default:
        break;
    }
    return result;
  };

  handleChange = (name) => (event) => {
    let filteredData = this.state.pageData;

    if (this.state.pageData.length > 0) {
      //  change the pageData based on proteinName
      if (event.target.value === "All") {
        filteredData = this.state.samples.filter(
          (sample) =>
            sample.standardGeneName === this.state.pageData[0].standardGeneName
        );

        const antibodies = [
          ...new Set(
            filteredData.map((sample) => {
              return sample.antibody;
            })
          ),
        ];

        if (name === "treatment") {
          this.setState({
            pageData: filteredData,
            [name]: "",
            antibodies: [...antibodies],
            antibody: "",
          });
        }
      } else if (name === "antibody") {
        filteredData = this.filterChange(
          "antibody",
          event.target.value,
          this.state.pageData[0].proteinName
        );

        if (filteredData.length > 0) {
          // set the state to the filteredData
          this.setState({
            pageData: filteredData,
            [name]: event.target.value,
          });
        }
      } else if (name === "treatment") {
        filteredData = this.filterChange(
          "treatment",
          event.target.value,
          this.state.pageData[0].proteinName
        );

        const antibodies = [
          ...new Set(
            filteredData.map((sample) => {
              return sample.antibody;
            })
          ),
        ];

        if (filteredData.length > 0) {
          // set the state to the filteredData
          this.setState({
            pageData: filteredData,
            antibodies: [...antibodies],
            antibody: "",
            [name]: event.target.value,
          });
        }
      }
    }
  };

  //  Custom Handlers
  handleSGDClick = () => {
    let url = "https://www.yeastgenome.org/locus/" + this.state.post.sgdId;
    let sgdWin = window.open(url, "_blank");
    sgdWin.focus();
  };

  toggleDrawer = (option) => () => {
    this.setState({
      drawer: option,
    });
  };

  handleTrackLoaderOpen = () => {
    this.setState({ trackLoader: true });
  };

  handleTrackLoaderClose = () => {
    this.setState({ trackLoader: false });
  };

  render() {
    const { classes } = this.props;
    // Retrieve only the header information, might need to change the sample model for that.
    // Then send all the samples to replicate tabs as props which uses map to create each individual sections, which are themselves individual components

    // console.log(this.state.pageData);

    const { loading, message } = this.state;

    const post = loading ? (
      <Typography component="div" className={classes.center}>
        <Typography component="p" variant="subtitle1">
          {message}
        </Typography>
        <LinearProgress variant="query" />
      </Typography>
    ) : (
      <Grid container className={classes.mainGrid} wrap="nowrap">
        <Grid item className={classes.leftSection}>
          <div className={classes.card}>
            {/* Header Section */}
            <Card>
              <CardContent>
                <Grid container spacing={1} alignItems={"center"}>
                  <Grid item sm={"auto"}>
                    <Typography gutterBottom variant="h4" component="h2">
                      {this.state.post.standardGeneName}
                      <Typography component="p" variant="caption">
                        <strong>Systematic Name: </strong>
                        {this.state.post.featureName}
                      </Typography>
                      <Typography component="p" variant="caption">
                        <strong>Aliases: </strong> {this.state.post.alias}
                      </Typography>
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container spacing={1} alignItems={"center"}>
                  <Grid item sm={"auto"}>
                    <Typography component="p" variant="body1">
                      {this.state.post.description}
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
              <Divider variant="middle" />

              {/* Action toolbar */}
              <CardActions>
                <Grid container alignItems={"center"} justify={"space-between"}>
                  <Grid item sm={"auto"}>
                    <Button
                      size="small"
                      color="primary"
                      onClick={this.handleTrackLoaderOpen}
                    >
                      <TrackHubIcon className={classes.leftIcon} />
                      UCSC Genome Browser
                    </Button>
                    <Tooltip
                      title="Saccharomyces Genome Database"
                      aria-label="Go to Saccharomyces Genome Database"
                    >
                      <Button
                        size="small"
                        color="primary"
                        onClick={this.handleSGDClick}
                      >
                        <LaunchIcon className={classes.leftIcon} />
                        SGD
                      </Button>
                    </Tooltip>

                    <Button
                      size="small"
                      color="primary"
                      onClick={this.toggleDrawer(true)}
                    >
                      <InfoIcon className={classes.leftIcon} />
                      Meta Data
                    </Button>
                  </Grid>
                  <Grid item sm={"auto"} />
                </Grid>
              </CardActions>

              {/* Drawer to show all the individual sample stats */}
              <Drawer
                anchor="top"
                open={this.state.drawer}
                onClose={this.toggleDrawer(false)}
              >
                <div tabIndex={0} role="button">
                  {/* Sending the toggleDrawer function as a prop that is called inside sampleStats.js */}
                  <SnackbarProvider
                    maxSnack={1}
                    anchorOrigin={{ vertical: "top", horizontal: "right" }}
                    hideIconVariant={true}
                  >
                    <SampleStatsTable
                      stats={this.state.pageData}
                      handleBack={this.toggleDrawer(false)}
                    />
                  </SnackbarProvider>
                </div>
              </Drawer>
            </Card>

            <br />
            {/* contents - ScrollSpy */}
            <div className={classes.scrollSpy}>
              <ScrollSpy />
            </div>

            <Paper className={classes.filterContainer}>
              <Grid container spacing={1} alignItems={"center"}>
                <Grid item>
                  <TextField
                    id="select-celltype"
                    select
                    label="Treatment"
                    variant="outlined"
                    className={classes.textField}
                    value={this.state.treatment}
                    onChange={this.handleChange("treatment")}
                    SelectProps={{
                      MenuProps: {
                        className: classes.menu,
                      },
                    }}
                    margin="dense"
                  >
                    {this.state.treatments.map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>

                <Grid item>
                  <TextField
                    id="select-celltype"
                    select
                    label="Antibody"
                    variant="outlined"
                    className={classes.textField}
                    value={this.state.antibody}
                    onChange={this.handleChange("antibody")}
                    SelectProps={{
                      MenuProps: {
                        className: classes.menu,
                      },
                    }}
                    margin="dense"
                  >
                    {this.state.antibodies.map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </Grid>
            </Paper>

            {/* Second Section with Tabs */}
            <ReplicateTabs samples={this.state.pageData} />
          </div>
        </Grid>
      </Grid>
    );
    return (
      <div>
        {/* main Sample Content */}
        <Typography component="div" className={classes.center}>
          {post}
        </Typography>

        <Dialog
          open={this.state.trackLoader}
          onClose={this.handleTrackLoaderClose}
          aria-labelledby="trackLoader-dialog-title"
          maxWidth={"md"}
        >
          <DialogTitle id="trackLoader-dialog-title">
            UCSC Genome Browser
            <IconButton
              aria-label="Close"
              className={classes.closeButton}
              onClick={this.handleTrackLoaderClose}
            >
              <CloseIcon color="primary" />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Clicking on
              <strong>
                <em>visualize </em>
              </strong>
              loads the associated sample's tracks onto the UCSC genome browser.
              Whereas, clicling on
              <strong>
                <em>visualize all</em>
              </strong>
              {"  "}
              loads all the samples listed.
            </DialogContentText>
            <br />
            <Divider />
            <TrackLoader samples={this.state.pageData} />
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

Sample.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Sample);
