import React from "react";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import UnfoldMore from "@material-ui/icons/UnfoldMore";
import Fab from "@material-ui/core/Fab";
import { Divider } from "@material-ui/core";

const styles = theme => ({
  leftIcon: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  scrollSpyLinks: {
    textDecoration: "none",
    color: "black",
    width: "100%",
    height: "100%",
    padding: 5
  }
});

class ScrollSpy extends React.Component {
  state = {
    anchorEl: null
  };

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleGoTop = () => {
    this.setState({ anchorEl: null });
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
  };

  render() {
    const { anchorEl } = this.state;
    const { classes } = this.props;

    return (
      <div>
        <Fab
          size="small"
          color="primary"
          aria-label="more"
          className={classes.margin}
          aria-owns={anchorEl ? "simple-menu" : undefined}
          aria-haspopup="true"
          onClick={this.handleClick}
        >
          <UnfoldMore />
        </Fab>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
        >
          <MenuItem onClick={this.handleClose}>
            <a href="#section1" className={classes.scrollSpyLinks}>
              Coding Features
            </a>
          </MenuItem>
          <MenuItem onClick={this.handleClose}>
            <a href="#section2" className={classes.scrollSpyLinks}>
              Non-Coding Features
            </a>
          </MenuItem>
          <MenuItem onClick={this.handleClose}>
            <a href="#section3" className={classes.scrollSpyLinks}>
              Other Features
            </a>
          </MenuItem>
          <MenuItem onClick={this.handleClose}>
            <a href="#section4" className={classes.scrollSpyLinks}>
              Motif Analysis
            </a>
          </MenuItem>
          <Divider variant="middle" />
          <MenuItem onClick={this.handleGoTop}>
            <span className={classes.scrollSpyLinks}>Back to Top</span>
          </MenuItem>
        </Menu>
      </div>
    );
  }
}

ScrollSpy.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(ScrollSpy);
