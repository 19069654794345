import React from "react";
import axios from "axios";
import { Link } from "react-router-dom";

import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";

import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";

import CardActions from "@material-ui/core/CardActions";
import ArrowBack from "@material-ui/icons/ArrowBack";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import UnPublishIcon from "@material-ui/icons/Lock";
import PublishIcon from "@material-ui/icons/LockOpen";
import EditIcon from "@material-ui/icons/EditOutlined";
import CartIcon from "@material-ui/icons/ShoppingCartOutlined";
import CartFilledIcon from "@material-ui/icons/ShoppingCart";
import DownloadIcon from "@material-ui/icons/GetApp";
import DataContext from "./DataContext";

import { withSnackbar } from "notistack";

// retrieve app configuration settings
import Config from "../Config";

const styles = (theme) => ({
  fullList: {
    width: "auto",
    height: "100%",
  },
  table: {
    width: "auto",
    margin: "0 auto",
  },
  borderLine: {
    border: `1px solid ${theme.palette.divider}`,
    // borderLeft: `2px solid ${theme.palette.divider}`,
    padding: `${theme.spacing(1)}px ${theme.spacing(1) * 1}px`,
  },
  statsContainer: {
    padding: `${theme.spacing(1)}px ${theme.spacing(1) * 5}px`,
    marginTop: 2,
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
    fontSize: 20,
  },
  tableHeader: {
    color: "#fff",
    backgroundColor: "#5c6bc0",
    border: `2px solid #5c6bc0`,
  },
  publicColor: {
    color: "#43a047",
  },
  privateColor: {
    color: "#dd2c00",
  },
  removeCart: {
    color: "green",
  },
  linkTags: {
    color: theme.palette.primary,
    textDecoration: "none",
  },
});

class SampleStatsTable extends React.Component {
  static contextType = DataContext;
  state = {
    stats: null,
  };

  handleSamplePublication = (id, value, replicateId) => () => {
    // The patchURL should be coming from a config file.
    var patchURL =
      Config.settings.apiURL + Config.settings.samplesEndpoint + "/" + id;
    var updateArray = [{ propName: "isPublic", value: value }];

    // changing the public status using the state
    const newStats = this.props.stats.map((item) => {
      if (item._id === id) {
        item.isPublic = value;
      }
      return item;
    });

    axios
      .patch(patchURL, updateArray)
      .then((res) => {
        if (res.statusText === "OK") {
          console.log("Sucess", updateArray[0]);
          value
            ? this.props.enqueueSnackbar(replicateId + " is published", {
                variant: "success",
              })
            : this.props.enqueueSnackbar(replicateId + " is private", {
                variant: "info",
              });
          this.setState({
            stats: newStats,
          });
        } else {
          // wondering when this would happen ?
          console.log("Failed", updateArray[0]);
          console.log(res);
          this.props.enqueueSnackbar("ERROR : Check log in the console !", {
            variant: "error",
            autoHideDuration: 2000,
          });
        }
      })
      .catch((error) => {
        console.log("Unable to update the sample using PATCH");
        console.log(error);
        this.props.enqueueSnackbar(error.message + " / Server Offline", {
          variant: "error",
          autoHideDuration: 2000,
        });
      });
  };

  render() {
    const { classes } = this.props;

    const sampleStats = this.props.stats.map((item) => {
      // Status of the sample
      const sampleStatus = item.isPublic ? (
        <PublishIcon
          fontSize="small"
          className={classes.publicColor}
          onClick={this.handleSamplePublication(item._id, false, item.sampleId)}
        />
      ) : (
        <UnPublishIcon
          fontSize="small"
          className={classes.privateColor}
          onClick={this.handleSamplePublication(item._id, true, item.sampleId)}
        />
      );

      // Boolean to set the cart icon if a sample is included in the cart
      let myCart = this.context.cartItems.map((citem) => {
        return citem.sampleId;
      });

      let includedInCart = myCart.includes(item.sampleId) ? (
        <CartFilledIcon
          className={classes.removeCart}
          onClick={this.context.removeFromCart(item)}
          fontSize="small"
        />
      ) : (
        <CartIcon
          color="primary"
          fontSize="small"
          onClick={this.context.addToCart(item)}
        />
      );
      // console.log(item.downloadLink);

      let Download = (
        <a
          href={item.downloadLink}
          rel="noopener noreferrer"
          className={classes.linkTags}
        >
          <DownloadIcon fontSize="small" color="primary" />
        </a>
      );

      return (
        <TableRow key={item._id} className={classes.borderLine}>
          <TableCell align="center" className={classes.borderLine}>
            {item.sampleId}
          </TableCell>
          <TableCell align="center" className={classes.borderLine}>
            {item.runId}
          </TableCell>
          <TableCell align="center" className={classes.borderLine}>
            {item.assayType}
          </TableCell>
          <TableCell align="center" className={classes.borderLine}>
            {item.epitopeTag}
          </TableCell>
          <TableCell align="center" className={classes.borderLine}>
            {item.antibody}
          </TableCell>
          <TableCell align="center" className={classes.borderLine}>
            {item.growthMedia} / {item.treatments}
          </TableCell>
          <TableCell align="center" className={classes.borderLine}>
            {item.totalReads}
          </TableCell>
          <TableCell align="center" className={classes.borderLine}>
            {item.mappedReads} ({item.mappedReadPercent})
          </TableCell>
          <TableCell align="center" className={classes.borderLine}>
            {item.uniquelyMappedReads} ({item.uniquelyMappedPercent})
          </TableCell>
          <TableCell align="center" className={classes.borderLine}>
            {item.dedupUniquelyMappedReads} ({item.deduplicatedPercent})
          </TableCell>

          {Config.settings.siteAvailability === "private" ? (
            <React.Fragment>
              <TableCell align="center" className={classes.borderLine}>
                <Tooltip title="Edit Sample" aria-label="Edit Sample">
                  <Link to={"/edit/" + item._id}>
                    <EditIcon fontSize="small" color="primary" />
                  </Link>
                </Tooltip>
              </TableCell>

              <TableCell align="center" className={classes.borderLine}>
                <Tooltip title="Publish Sample" aria-label="Publish Sample">
                  {sampleStatus}
                </Tooltip>
              </TableCell>
            </React.Fragment>
          ) : (
            " "
          )}

          {/* commented download link */}
          <TableCell align="center" className={classes.borderLine}>
            {Download}
          </TableCell>

          <TableCell align="center" className={classes.borderLine}>
            {includedInCart}
          </TableCell>
        </TableRow>
      );
    });

    return (
      <CardContent className={classes.fullList}>
        <CardActions>
          <Grid container alignItems={"center"} justify={"space-between"}>
            <Grid item sm={"auto"}>
              <Tooltip title="Go Back" aria-label="Go Back">
                <Button
                  size="small"
                  color="primary"
                  onClick={this.props.handleBack}
                >
                  <ArrowBack />
                </Button>
              </Tooltip>
            </Grid>
            <Grid item sm={"auto"}>
              {/* Add Card Actions here or filter menu  */}
            </Grid>
          </Grid>
        </CardActions>

        <Divider />
        <br />

        <CardContent>
          <Typography style={{ textAlign: "center" }} gutterBottom variant="h6">
            Showing metadata for {this.props.stats.length} Sample(s)
          </Typography>
        </CardContent>

        <Table className={classes.table}>
          <TableHead>
            <TableRow className={classes.borderLine}>
              <TableCell align="center" className={classes.borderLine}>
                SampleID
              </TableCell>
              <TableCell align="center" className={classes.borderLine}>
                RunID
              </TableCell>
              <TableCell align="center" className={classes.borderLine}>
                Assay
              </TableCell>
              <TableCell align="center" className={classes.borderLine}>
                Epitope Tag
              </TableCell>
              <TableCell align="center" className={classes.borderLine}>
                Antibody
              </TableCell>
              <TableCell align="center" className={classes.borderLine}>
                Condition
              </TableCell>
              <TableCell align="center" className={classes.borderLine}>
                Total Sequencing Reads
              </TableCell>
              <TableCell align="center" className={classes.borderLine}>
                Mapped Read Count
              </TableCell>
              <TableCell align="center" className={classes.borderLine}>
                Uniquely Mapped Reads
              </TableCell>
              <TableCell align="center" className={classes.borderLine}>
                DeDuplicated Count
              </TableCell>
              {Config.settings.siteAvailability === "private" ? (
                <React.Fragment>
                  <TableCell align="center" className={classes.borderLine}>
                    Edit
                  </TableCell>

                  <TableCell align="center" className={classes.borderLine}>
                    Publish
                  </TableCell>
                </React.Fragment>
              ) : (
                " "
              )}
              <TableCell align="center" className={classes.borderLine}>
                Direct Download
              </TableCell>
              <TableCell align="center" className={classes.borderLine}>
                Bulk Download
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{sampleStats}</TableBody>
        </Table>
      </CardContent>
    );
  }
}

SampleStatsTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withSnackbar(SampleStatsTable));
