import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Popover from "@material-ui/core/Popover";
import Button from "@material-ui/core/Button";

const styles = {
  card: {
    maxWidth: 1100,
  },
  bottomSection: {
    // border: '2px solid blue'
  },
  featureHeatmap: {
    width: 230,
  },
  mainContainer: {
    overflow: "scroll",
  },
  sectionTitle: {
    fontSize: 18,
  },
  internalHeatmap: {
    width: 230,
  },
  colorBar: {
    height: 360,
    width: 20,
    marginTop: -15,
    marginLeft: -17,
  },
  cut: {
    height: 20,
    width: 20,
    background: "#043927",
    border: `1px solid black`,
  },
  sut: {
    height: 20,
    width: 20,
    background: "#0B6623",
    border: `1px solid black`,
  },
  xut: {
    height: 20,
    width: 20,
    background: "#00A86B",
    border: `1px solid black`,
  },
  ncr: {
    height: 20,
    width: 20,
    background: "#4CBB17",
    border: `1px solid black`,
  },
  delta: {
    height: 20,
    width: 20,
    background: "#CBFFE5",
    border: `1px solid black`,
  },
  omega: {
    height: 20,
    width: 20,
    background: "#36DEAB",
    border: `1px solid black`,
  },
  sigma: {
    height: 20,
    width: 20,
    background: "#37F2B9",
    border: `1px solid black`,
  },
  tau: {
    height: 20,
    width: 20,
    background: "#05AD83",
    border: `1px solid black`,
  },

  trna: {
    height: 20,
    width: 20,
    background: "#663399",
    border: `1px solid black`,
  },
  etc: {
    height: 20,
    width: 20,
    background: "#9370DB",
    border: `1px solid black`,
  },
  nc: {
    height: 20,
    width: 20,
    background: "#9932CC",
    border: `1px solid black`,
  },
};

class TranscribedNonCoding extends React.Component {
  // set the imageUrl to the props and when tab is changed , update the state to subsector images
  state = {
    imageUrl: this.props.images,
  };

  // Used to update the TranscribedNonCoding , when new replicate tab is selected
  componentDidUpdate(prevProps) {
    if (prevProps.images !== this.props.images) {
      this.setState({
        selectedTab: 0,
        imageUrl: this.props.images,
      });
    }
  }

  handleClick = (event) => {
    this.setState({
      anchorEl: event.currentTarget,
    });
  };

  handleClose = () => {
    this.setState({
      anchorEl: null,
    });
  };

  render() {
    const { classes } = this.props;
    const { imageUrl } = this.state;
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);

    return (
      <div className={classes.card}>
        {/* Legend pop over */}
        <Popover
          id="simple-popper"
          open={open}
          anchorEl={anchorEl}
          onClose={this.handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
        >
          <Paper>
            <CardContent>
              <Typography variant="subtitle1" gutterBottom>
                Gene Categories
              </Typography>
              <br />

              <Grid container spacing={4} direction="row" wrap="nowrap">
                <Grid item>
                  <Grid
                    container
                    spacing={1}
                    direction="column"
                    wrap="wrap"
                    justify="flex-start"
                  >
                    <Grid item>
                      <Grid
                        container
                        spacing={1}
                        direction="row"
                        wrap="nowrap"
                        justify="flex-start"
                      >
                        <Grid item>
                          <div className={classes.cut} />
                        </Grid>
                        <Grid item>
                          <Typography gutterBottom>CUT</Typography>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item>
                      <Grid
                        container
                        spacing={1}
                        direction="row"
                        wrap="nowrap"
                        justify="flex-start"
                      >
                        <Grid item>
                          <div className={classes.sut} />
                        </Grid>
                        <Grid item>
                          <Typography gutterBottom>SUT</Typography>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item>
                      <Grid
                        container
                        spacing={1}
                        direction="row"
                        wrap="nowrap"
                        justify="flex-start"
                      >
                        <Grid item>
                          <div className={classes.xut} />
                        </Grid>
                        <Grid item>
                          <Typography gutterBottom> XUT </Typography>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item>
                      <Grid
                        container
                        spacing={1}
                        direction="row"
                        wrap="nowrap"
                        justify="flex-start"
                      >
                        <Grid item>
                          <div className={classes.ncr} />
                        </Grid>
                        <Grid item>
                          <Typography gutterBottom> NCR </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item>
                  <Grid
                    container
                    spacing={1}
                    direction="column"
                    wrap="wrap"
                    justify="flex-start"
                  >
                    <Grid item>
                      <Grid
                        container
                        spacing={1}
                        direction="row"
                        wrap="nowrap"
                        justify="flex-start"
                      >
                        <Grid item>
                          <div className={classes.delta} />
                        </Grid>
                        <Grid item>
                          <Typography gutterBottom>Ty LTR Delta</Typography>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item>
                      <Grid
                        container
                        spacing={1}
                        direction="row"
                        wrap="nowrap"
                        justify="flex-start"
                      >
                        <Grid item>
                          <div className={classes.omega} />
                        </Grid>
                        <Grid item>
                          <Typography gutterBottom>Ty LTR Omega</Typography>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item>
                      <Grid
                        container
                        spacing={1}
                        direction="row"
                        wrap="nowrap"
                        justify="flex-start"
                      >
                        <Grid item>
                          <div className={classes.sigma} />
                        </Grid>
                        <Grid item>
                          <Typography gutterBottom> Ty LTR Sigma </Typography>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item>
                      <Grid
                        container
                        spacing={1}
                        direction="row"
                        wrap="nowrap"
                        justify="flex-start"
                      >
                        <Grid item>
                          <div className={classes.tau} />
                        </Grid>
                        <Grid item>
                          <Typography gutterBottom> Ty LTR Tau </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item>
                  <Grid
                    container
                    spacing={1}
                    direction="column"
                    wrap="wrap"
                    justify="flex-start"
                  >
                    <Grid item>
                      <Grid
                        container
                        spacing={1}
                        direction="row"
                        wrap="nowrap"
                        justify="flex-start"
                      >
                        <Grid item>
                          <div className={classes.trna} />
                        </Grid>
                        <Grid item>
                          <Typography gutterBottom>tRNA</Typography>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item>
                      <Grid
                        container
                        spacing={1}
                        direction="row"
                        wrap="nowrap"
                        justify="flex-start"
                      >
                        <Grid item>
                          <div className={classes.etc} />
                        </Grid>
                        <Grid item>
                          <Typography gutterBottom>ETC</Typography>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item>
                      <Grid
                        container
                        spacing={1}
                        direction="row"
                        wrap="nowrap"
                        justify="flex-start"
                      >
                        <Grid item>
                          <div className={classes.nc} />
                        </Grid>
                        <Grid item>
                          <Typography gutterBottom> 3NC </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Paper>
        </Popover>

        {/* Header */}
        <Typography
          variant="overline"
          component="h5"
          gutterBottom
          className={classes.sectionTitle}
        >
          Transcribed Features - Non Coding
        </Typography>

        <Paper>
          <CardContent className={classes.sectionHolder}>
            <Typography component="div">
              <Button
                size="small"
                color="primary"
                style={{ float: "right", marginBottom: 0 }}
                onClick={this.handleClick}
              >
                Legend
              </Button>
              {/* Chexmix and Subsector Heatmap Section */}
              <Grid
                container
                spacing={2}
                direction="column"
                wrap="nowrap"
                justify="flex-start"
                className={classes.mainContainer}
              >
                {/* Buffer Section */}
                <Grid item />

                {/* Bottom Section */}
                <Grid item className={classes.bottomSection}>
                  <Grid
                    container
                    direction="row"
                    justify="space-evenly"
                    alignItems="center"
                    spacing={0}
                  >
                    <Grid item className={classes.leftSection}>
                      <Grid
                        container
                        direction="row"
                        justify="space-evenly"
                        alignItems="center"
                        spacing={1}
                        wrap="nowrap"
                      >
                        <Grid item>
                          <img
                            src={imageUrl.RNAPol2Heatmap}
                            alt="RNAPol2 Heatmap"
                            className={classes.featureHeatmap}
                          />
                        </Grid>
                        <Grid item>
                          <img
                            src={imageUrl.RNAPol2Colorbar}
                            alt="RNAPol2 Colorbar"
                            className={classes.colorBar}
                          />
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item className={classes.leftSection}>
                      <Grid
                        container
                        direction="row"
                        justify="space-evenly"
                        alignItems="center"
                        spacing={1}
                        wrap="nowrap"
                      >
                        <Grid item>
                          <img
                            src={imageUrl.ltrHeatmap}
                            alt="Bound Features Heatmap"
                            className={classes.featureHeatmap}
                          />
                        </Grid>
                        <Grid item>
                          <img
                            src={imageUrl.ltrColorbar}
                            alt="Bound Features Colorbar"
                            className={classes.colorBar}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item className={classes.leftSection}>
                      <Grid
                        container
                        direction="row"
                        justify="space-evenly"
                        alignItems="center"
                        spacing={1}
                        wrap="nowrap"
                      >
                        <Grid item>
                          <img
                            src={imageUrl.RNAPol3Heatmap}
                            alt="Bound Features Heatmap"
                            className={classes.featureHeatmap}
                          />
                        </Grid>
                        <Grid item>
                          <img
                            src={imageUrl.RNAPol3Colorbar}
                            alt="Bound Features Colorbar"
                            className={classes.colorBar}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Typography>
          </CardContent>
        </Paper>
      </div>
    );
  }
}

TranscribedNonCoding.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(TranscribedNonCoding);
