import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";

const styles = {
  card: {
    maxWidth: 1200,
    margin: "0 auto",
  },
  bottomSection: {},
  featureHeatmap: {
    width: 250,
  },
  mainContainer: {
    overflow: "scroll",
    paddingTop: 10,
  },
  sectionTitle: {
    fontSize: 18,
  },
  internalHeatmap: {
    width: 230,
  },
};

class OtherFeaturesSection extends React.Component {
  // set the imageUrl to the props and when tab is changed , update the state to subsector images
  state = {
    imageUrl: this.props.images,
  };

  // Used to update the OtherFeaturesSection , when new replicate tab is selected
  componentDidUpdate(prevProps) {
    if (prevProps.images !== this.props.images) {
      this.setState({
        selectedTab: 0,
        imageUrl: this.props.images,
      });
    }
  }

  render() {
    const { classes } = this.props;
    const { imageUrl } = this.state;

    return (
      <div className={classes.card}>
        <Paper>
          <CardContent className={classes.sectionHolder}>
            <Typography component="div">
              {/* Chexmix and Subsector Heatmap Section */}
              <Grid
                container
                spacing={2}
                direction="column"
                wrap="nowrap"
                justify="flex-start"
                className={classes.mainContainer}
              >
                {/* Buffer Section */}
                <Grid item />

                {/* Bottom Section */}
                <Grid item className={classes.bottomSection}>
                  <Grid
                    container
                    direction="row"
                    justify="space-evenly"
                    alignItems="center"
                    spacing={3}
                  >
                    <Grid item>
                      <img
                        src={imageUrl.arsHeatmap}
                        alt="Xelement Heatmap"
                        className={classes.featureHeatmap}
                      />
                    </Grid>
                    <Grid item>
                      <img
                        src={imageUrl.centromereHeatmap}
                        alt="Centromere Heatmap"
                        className={classes.featureHeatmap}
                      />
                    </Grid>
                    <Grid item>
                      <img
                        src={imageUrl.xelementHeatmap}
                        alt="ARS Heatmap"
                        className={classes.featureHeatmap}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Typography>
          </CardContent>
        </Paper>
      </div>
    );
  }
}

OtherFeaturesSection.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(OtherFeaturesSection);
