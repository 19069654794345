import React, { Component } from "react";
import { Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { Typography, Button, Paper, Divider } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import DataContext from "./DataContext";

const styles = theme => ({
  simpleDiv: {
    marginRight: 15,
    marginBottom: 15,
    padding: 10,
    height: 50,
    width: 50,
    border: "2px solid black"
  },
  myLinks: {
    textDecoration: "none",
    color: "black"
  },
  containerGrid: {
    padding: 20
  },
  root: {
    width: "85vw",
    margin: "0 auto",
    [theme.breakpoints.down("sm")]: {
      width: "98vw",
      margin: "0 auto"
    },
    [theme.breakpoints.up("md")]: {
      width: "80vw",
      margin: "0 auto"
    },
    background: "linear-gradient(to bottom,#e8eaf6,#e8eaf6)"
  },
  content: {
    flex: "1 0 auto"
  },
  TextField: {
    width: "100%"
  },
  filterBar: {
    width: 500,
    padding: 10,
    marginTop: 20,
    marginLeft: 20
  }
});

class HomePage2 extends Component {
  static contextType = DataContext;

  state = {
    data: this.context.data,
    value: ""
  };

  handleChange = event => {
    const filteredItems = this.context.data.filter(item => {
      return item.standardGeneName.startsWith(event.target.value.toUpperCase());
    });
    // console.log(filteredItems);

    this.setState({ value: event.target.value, data: filteredItems });
  };

  render() {
    const { classes } = this.props;

    var cellLineData = this.state.data.map(sample => {
      return sample.standardGeneName;
    });

    var count = cellLineData.reduce((tally, gene) => {
      tally[gene] = (tally[gene] || 0) + 1;
      return tally;
    }, {});

    // console.log(Object.keys(count).sort());

    var myDict = {};

    // create an object with Alphbets as keys
    Object.keys(count)
      .sort()
      .map(item => {
        // get the first letter
        let letter = item.toUpperCase().charAt(0);
        // fill the array with alphabets as keys
        if (!myDict[letter]) {
          myDict[letter] = [item];
        } else {
          myDict[letter].push(item);
        }
        return letter;
      });

    // reference to iterate through the object of arrays
    // https://zellwk.com/blog/looping-through-js-objects/

    const entries = Object.entries(myDict);
    // console.log(entries);

    const sections = [];
    for (const [letter, proteinNames] of entries) {
      const alphaitems = proteinNames.map(item => {
        return (
          <Grid item key={letter + "-" + item}>
            <Link
              to={"/factor/" + item.toUpperCase()}
              className={classes.myLinks}
            >
              <Button variant="outlined" key={item}>
                {item}
              </Button>
            </Link>
          </Grid>
        );
      });
      sections.push(
        <Grid
          container
          spacing={1}
          key={letter + "+" + letter}
          className={classes.containerGrid}
        >
          <div className={classes.simpleDiv}>
            <Typography variant="h5">{letter}</Typography>
          </div>
          {alphaitems}
        </Grid>
      );
      sections.push(<Divider key={letter + "_" + letter} />);
    }

    return (
      <div className={classes.root}>
        <Paper square className={classes.filterBar}>
          <Typography variant="h5">Explore</Typography>
          <Typography variant="subtitle2" gutterBottom>
            Using standard gene names from Saccharomyces Genome Database. No.of
            Targets: {Object.keys(count).sort().length}
          </Typography>
          <Divider />
          <br />
          <TextField
            id="standard-name"
            label="Search & Filter"
            value={this.state.value}
            onChange={this.handleChange}
            variant="outlined"
            className={classes.TextField}
          />
        </Paper>
        <Grid container direction="column" className={classes.containerGrid}>
          <Paper square>{sections}</Paper>
        </Grid>
        <br />
      </div>
    );
  }
}

export default withStyles(styles)(HomePage2);
