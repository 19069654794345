import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { Paper, Typography, Divider } from "@material-ui/core";

const styles = (theme) => ({
  root: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  mainHeader: {
    display: "flex",
    alignItems: "center",
    height: 50,
    paddingLeft: theme.spacing(5),
  },
  content: {
    padding: 10,
    margin: "0 auto",
    width: "60vw",
  },
  bottomSpace: {
    marginBottom: 10,
  },
});

class AboutUs extends React.Component {
  render() {
    const { classes } = this.props;
    // Setting the title of the browser tab
    document.title = "About | YEP";

    return (
      <div className={classes.root}>
        <Paper square elevation={0} className={classes.mainHeader}>
          <Typography variant="h4" gutterBottom>
            About Us
          </Typography>
        </Paper>
        <br />
        <Paper square elevation={1} className={classes.content}>
          <Typography variant="h5" gutterBottom className={classes.bottomSpace}>
            A high-resolution protein architecture of the budding yeast genome
          </Typography>
          <Divider />
          <br />
          <Typography
            component="div"
            variant="body1"
            gutterBottom
            className={classes.bottomSpace}
          >
            <strong> Authors:</strong>
            <p>
              Matthew J. Rossi, Prashant K. Kuntala, William K.M. Lai, Naomi
              Yamada, Nitika Badjatia, Chitvan Mittal, Guray Kuzu, Kylie
              Bocklund, Nina P. Farrell, Thomas R. Blanda, Joshua D. Mairose,
              Ann V. Basting, Katelyn S. Mistretta, David J. Rocco, Emily S.
              Perkinson, Gretta D. Kellogg, Shaun Mahony, B. Franklin Pugh*
            </p>
          </Typography>
          <Typography component="div" variant="body1" gutterBottom>
            <strong>Principal Investigator:</strong>
            <p>
              B. Franklin Pugh, Cornell University, New York, 14853. <br />
              email: fp265@cornell.edu
            </p>
          </Typography>
          <Typography component="div" variant="body1" gutterBottom>
            <strong>Publication details:</strong>
            <p>
            Published in Nature, March 10, 2021. DOI: https://dx.doi.org/10.1038/s41586-021-03314-8
            <br />
            <br />
            See Supplementary Data 4 for a list of where to find available data and code online. All raw sequencing data and peak files from this study
            are available at the NCBI GEO (https://www.ncbi.nlm.nih.gov/geo/) under accession number GSE147927. Processed data are available at
              https://doi.org/10.26208/rykf-6050.
            <br />
            <br />
            We warn that single-replicate data files are not likely to have meaningful data and should not be used without further replication. All
            underlying data used to generate composite plots, coordinate files and script parameters for Figs. 2–5, Extended Data Figs. 4, 5, 7, 8b,
            and Supplementary Fig. 1 can be downloaded from https://github.com/CEGRcode/2021-Rossi_Nature. Final composite plot values can be found in
            Supplementary Data 5.
            </p>
          </Typography>
        </Paper>
        <br />
      </div>
    );
  }
}

AboutUs.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withRouter(withStyles(styles)(AboutUs));
