import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import {
  Typography,
  Card,
  CardContent,
  Grid,
  CardActions,
  Button,
  Divider,
  Tooltip
} from "@material-ui/core";
import LaunchIcon from "@material-ui/icons/Launch";
import TrackHubIcon from "@material-ui/icons/AssessmentOutlined";
import InfoIcon from "@material-ui/icons/InfoOutlined";

const styles = theme => ({
  center: {
    margin: "auto",
    maxWidth: 1200,
    padding: 10
  },
  card: {
    maxWidth: 1200
  },
  leftIcon: {
    marginRight: theme.spacing(1)
  },
  searchbar: {
    width: 1100
  },
  scrollSpy: {
    position: "fixed"
  },
  textField: {
    marginLeft: 10,
    width: 160
  },
  filterContainer: {
    marginLeft: 60
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});

class Sample extends Component {
  state = {
    post: {
      _id: "5d810e11f231531d18135ef5",
      isPublic: false,
      featureName: "YJR060W",
      standardGeneName: "CBF1",
      commonName: "CBF1",
      sgdId: "S000003821",
      alias: "GFII , CP1 , CPF1 , CEP1",
      description:
        "Basic helix-loop-helix (bHLH) protein; forms homodimer to bind E-box consensus sequence CACGTG present at MET gene promoters and centromere DNA element I (CDEI); affects nucleosome positioning at this motif; associates with other transcription factors such as Met4p and Isw1p to mediate transcriptional activation or repression; associates with kinetochore proteins, required for chromosome segregation; protein abundance increases in response to DNA replication stress",
      featureType: "ORF",
      featureQualifier: "Verified",
      isMergedReplicate: false,
      downloadLink:
        "https://pluto.vmhost.psu.edu:8093/images/Downloads/10352_YEP.tar.gz",
      sampleId: 10352,
      runId: 180,
      genome: "sacCer3",
      project: "YEP",
      author: "Matt",
      assayType: "ChIP-exo",
      peaks: "0",
      motifCount: 3,
      epitopeTag: "-",
      treatments: "Normal",
      growthMedia: "YPD",
      antibody: "TAP-tag: Sigma i5006",
      totalReads: "5,362,477",
      mappedReads: "4,762,442",
      mappedReadPercent: "88.8%",
      uniquelyMappedReads: "4,192,574",
      uniquelyMappedPercent: "78.2%",
      dedupUniquelyMappedReads: "1,671,933",
      deduplicatedPercent: "31.2%"
    }
  };

  render() {
    const { classes } = this.props;
    const post = (
      <div className={classes.card}>
        {/* Header Section */}
        <Card>
          <CardContent>
            <Grid container spacing={1} alignItems={"center"}>
              <Grid item sm={"auto"}>
                <Typography gutterBottom variant="h4" component="h2">
                  {this.state.post.standardGeneName}
                  <Typography component="p" variant="caption">
                    <strong>Systematic Name: </strong>
                    {this.state.post.featureName}
                  </Typography>
                  <Typography component="p" variant="caption">
                    <strong>Aliases: </strong> {this.state.post.alias}
                  </Typography>
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={1} alignItems={"center"}>
              <Grid item sm={"auto"}>
                <Typography component="p" variant="body1">
                  {this.state.post.description}
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
          <Divider variant="middle" />

          {/* Action toolbar */}
          <CardActions>
            <Grid container alignItems={"center"} justify={"space-between"}>
              <Grid item sm={"auto"}>
                <Button size="small" color="primary">
                  <TrackHubIcon className={classes.leftIcon} />
                  UCSC Genome Browser
                </Button>
                <Tooltip
                  title="Saccharomyces Genome Database"
                  aria-label="Go to Saccharomyces Genome Database"
                >
                  <Button size="small" color="primary">
                    <LaunchIcon className={classes.leftIcon} />
                    SGD
                  </Button>
                </Tooltip>

                <Button size="small" color="primary">
                  <InfoIcon className={classes.leftIcon} />
                  Meta Data
                </Button>
              </Grid>
              <Grid item sm={"auto"} />
            </Grid>
          </CardActions>
        </Card>
      </div>
    );
    return (
      <div>
        {/* main Sample Content */}
        <Typography component="div" className={classes.center}>
          {post}
        </Typography>
      </div>
    );
  }
}

Sample.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Sample);
