import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import { Button, CardActions } from "@material-ui/core";
import { Link } from "react-router-dom";
import InfoIcon from "@material-ui/icons/InfoOutlined";
import ListIcon from "@material-ui/icons/ViewList";
import AboutUsIcon from "@material-ui/icons/Group";
// import pdfLogo from "../pdfLogo.png";

// import image assets
// import pennstatelogo from "../pennstatelogo.png";
// import cegrlogo from "../CEGRlogo.png";

// Sub Components
import SearchBar from "./HelpComponents/SearchBar";
import DataContext from "./DataContext";

const styles = (theme) => ({
  root: {
    height: "100%",
    maxWidth: 980,
    margin: "0 auto",
    display: "flex",
    flexDirection: "column",
  },
  content: {
    flex: "1 0 auto",
    padding: 20,
  },
  footer: {
    /* Prevent Chrome, Opera, and Safari from letting these items shrink to smaller than their content's default minimum size. */
    flexShrink: 0,
  },
  jumbotron: {
    padding: "2rem 2rem",
    [theme.breakpoints.down("sm")]: {
      padding: "4rem 0rem",
    },
  },
  container: {
    maxWidth: "1140px",
    paddingRight: 15,
    paddingLeft: 15,
    margin: "auto",
  },
  center: {
    margin: "auto",
    maxWidth: 1140,
  },
  webLink: {
    textDecoration: "none",
  },
  iconHolder: {
    width: 300,
    margin: "5px auto",
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  pdfIcon: {
    height: 100,
    width: 100,
  },
});

class SimpleLandingPage extends React.Component {
  static contextType = DataContext;
  render() {
    const { classes } = this.props;
    const currentYear = new Date().getFullYear();

    // Setting the title of the browser tab
    document.title = "YEP | Yeast Epigenome Project";

    return (
      <div className={classes.root}>
        <div className={classes.content}>
          <Paper square>
            <CardActions>
              <Grid container alignItems={"center"} justify={"space-between"}>
                <Grid item sm={"auto"}>
                  {/* <Button size="small" color="primary">
                        <HomeIcon className={classes.leftIcon} />
                        Download Data
                      </Button> */}

                  <Link to="/explore">
                    <Button size="small" color="primary">
                      <ListIcon className={classes.leftIcon} />
                      Explore
                    </Button>
                  </Link>

                  <Link to="/help">
                    <Button size="small" color="primary">
                      <InfoIcon className={classes.leftIcon} />
                      Help
                    </Button>
                  </Link>
                </Grid>
                {/* <Grid
                  item
                  sm={"auto"}
                  style={{
                    float: "right",
                    border: "2px solid red",
                    padding: 2,
                  }}
                >
                  <Typography>showing-internal-data</Typography>
                </Grid> */}
                <Grid item>
                  <Link to="/aboutus">
                    <Button size="small" color="primary">
                      <AboutUsIcon className={classes.leftIcon} />
                      About Us
                    </Button>
                  </Link>
                </Grid>
              </Grid>
            </CardActions>
          </Paper>

          <Paper square>
            {/* Jumbotron or main message */}
            <div className={classes.jumbotron}>
              <div className={classes.container}>
                <Typography variant="h2" gutterBottom>
                  Yeast Epigenome Project
                </Typography>

                <Typography variant="subtitle1" gutterBottom>
                  <em>Saccharomyces cerevisiae</em> epigenome
                </Typography>
                <Divider />
                <Typography variant="caption" component="p" gutterBottom>
                  An improved understanding of how genes are regulated requires
                  an improved understanding of where gene regulatory proteins
                  are bound.
                </Typography>
              </div>
            </div>
            <Grid
              container
              spacing={2}
              direction="row"
              alignItems="center"
              justify="center"
            >
              <Grid item></Grid>
              {/* SearchBar */}
              <Grid item>
                <SearchBar suggestions={this.context.searchOptions} />
              </Grid>

              {/* add a pdf here. */}
              {/* <Grid item>
                <Grid container direction="row">
                  <Grid item>
                    <a
                      href="https://pluto.vmhost.psu.edu:8093/images/Yeast_Epigenome_Project_Manuscript.pdf"
                      rel="noopener noreferrer"
                      className={classes.links}
                    >
                      <img
                        src={pdfLogo}
                        alt="pdflogo"
                        className={classes.pdfIcon}
                      />
                    </a>
                  </Grid>
                  <Grid item style={{ paddingTop: 25 }}>
                    <span>
                      Yeast Epigenome <br /> Project <br /> Manuscript
                    </span>
                  </Grid>
                </Grid>
              </Grid> */}
            </Grid>
          </Paper>
          <br />
          <br />
          <Paper square>
            <Grid container spacing={2} direction="column" alignItems="center">
              <Grid item>
                <div className={classes.container}>
                  <Typography variant="h6">Overview</Typography>
                  <Divider />
                  <br />
                  <Typography variant="body1">
                    The genome-wide architecture of chromatin-associated
                    proteins that maintains chromosome integrity and gene
                    regulation is ill defined. Here we use chromatin
                    immunoprecipitation, exonuclease digestion and DNA
                    sequencing (ChIP–exo/seq) to define this architecture in
                    <em>Saccharomyces cerevisiae</em>. We identify 21
                    meta-assemblages consisting of roughly 400 different
                    proteins that are related to DNA replication, centromeres,
                    subtelomeres, transposons, and transcription by RNA
                    polymerase (Pol) I, II and III. Replication proteins engulf
                    a nucleosome, centromeres lack a nucleosome, and repressive
                    proteins encompass three nucleosomes at subtelomeric
                    X-elements. We find that most promoters associated with Pol
                    II evolved to lack a regulatory region, having only a core
                    promoter. These constitutive promoters comprise a short
                    nucleosome-free region (NFR) adjacent to a +1 nucleosome,
                    which together bind the transcription-initiation factor
                    TFIID to form a preinitiation complex (PIC). Positioned
                    insulators protect core promoters from upstream events. A
                    small fraction of promoters evolved an architecture for
                    inducibility, whereby sequence-specific transcription
                    factors (ssTFs) create a nucleosome-depleted region (NDR)
                    that is distinct from an NFR. We describe structural
                    interactions among ssTFs, their cognate cofactors and the
                    genome. These interactions include the nucleosomal and
                    transcriptional regulators RPD3-L, SAGA, NuA4, Tup1,
                    Mediator and SWI–SNF. Surprisingly, we do not detect
                    interactions between ssTFs and TFIID, suggesting that such
                    interactions do not stably occur. Our model for gene
                    induction involves ssTFs, cofactors and general factors such
                    as TBP and TFIIB, but not TFIID. By contrast, constitutive
                    transcription involves TFIID but not ssTFs and cofactors.
                    From this, we define a highly integrated network of gene
                    regulation by ssTFs.
                  </Typography>
                  <br />
                  <Divider />
                </div>
              </Grid>
              {/* Footer  Section */}
              {/* <Grid item className={classes.center}>
                <Grid
                  container
                  spacing={4}
                  alignItems={"center"}
                  direction="row"
                  justify="center"
                  alignContent="center"
                  className={classes.footer}
                >
                  <Grid item>
                    <img
                      src={pennstatelogo}
                      alt="pennstateLogo"
                      style={{ width: 160 }}
                    />
                  </Grid>
                  <Grid item>
                    <img src={cegrlogo} alt="cegrlogo" style={{ height: 55 }} />
                  </Grid>
                </Grid>
                <br />
                <Divider />
              </Grid> */}

              {/* copyright info */}
              <Grid item>
                <Typography variant="body2" style={{ paddingLeft: 10 }}>
                  &copy; 2019 - {currentYear}{" "}
                  <a
                    href="http://pughlab.mbg.cornell.edu/"
                    rel="noopener noreferrer"
                    className={classes.links}
                  >
                    Pugh Lab
                  </a>
                </Typography>
              </Grid>
            </Grid>
          </Paper>

          {/* Have a contributors page or some info */}
          {/* <SearchTable /> */}
        </div>
      </div>
    );
  }
}

SimpleLandingPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SimpleLandingPage);
