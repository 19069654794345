import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Paper, Button, DialogActions } from "@material-ui/core";
import DataContext from "./DataContext";
import LaunchIcon from "@material-ui/icons/Launch";
import CardContent from "@material-ui/core/CardContent";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TrackHubIcon from "@material-ui/icons/AssessmentOutlined";

// retrieve app configuration settings
import Config from "../Config";

const styles = theme => ({
  tableHeader: {
    backgroundColor: "#e8eaf6",
    fontSize: 15
  },
  borderLine: {
    border: `1px solid ${theme.palette.divider}`,
    color: "black",
    fontSize: 15
  },
  leftIcon: {
    marginRight: theme.spacing(1)
  }
});

class TrackLoader extends React.Component {
  static contextType = DataContext;

  singleTrackLoader = sampleId => () => {
    // https://stackoverflow.com/questions/94037/convert-character-to-ascii-code-in-javascript
    // retrieve the ascii code of the first letter of the protein, to determine the trackHub group
    const asciiCode = this.props.samples[0].standardGeneName
      .toUpperCase()
      .charCodeAt(0);
    console.log(asciiCode);

    let sampleTracks = Config.settings.trackHubPrefix;

    switch (true) {
      // A-H
      case asciiCode >= 65 && asciiCode <= 72:
        window.open(
          sampleTracks +
            "&yep1f" +
            sampleId +
            "=full&yep1p" +
            sampleId +
            "=dense&yep1r" +
            sampleId +
            "=full",
          "_blank"
        );
        break;

      // I-Q
      case asciiCode >= 73 && asciiCode <= 81:
        window.open(
          sampleTracks +
            "&yep2f" +
            sampleId +
            "=full&yep2p" +
            sampleId +
            "=dense&yep2r" +
            sampleId +
            "=full",
          "_blank"
        );
        break;

      // R-S
      case asciiCode >= 82 && asciiCode <= 83:
        window.open(
          sampleTracks +
            "&yep3f" +
            sampleId +
            "=full&yep3p" +
            sampleId +
            "=dense&yep3r" +
            sampleId +
            "=full",
          "_blank"
        );
        break;

      // T-Z
      case asciiCode >= 84 && asciiCode <= 90:
        window.open(
          sampleTracks +
            "&yep4f" +
            sampleId +
            "=full&yep4p" +
            sampleId +
            "=dense&yep4r" +
            sampleId +
            "=full",
          "_blank"
        );
        break;
      default:
        break;
    }
    // console.log("handleTrackHubClick() from Sample.js =>  Navigate to Track Hub");
  };

  handleTrackHubClick = () => {
    // https://stackoverflow.com/questions/94037/convert-character-to-ascii-code-in-javascript
    // retrieve the ascii code of the first letter of the protein, to determine the trackHub group
    const asciiCode = this.props.samples[0].standardGeneName
      .toUpperCase()
      .charCodeAt(0);
    // console.log(asciiCode);

    let sampleTracks = Config.settings.trackHubPrefix;
    let sampleIds = "";

    switch (true) {
      // A-H
      case asciiCode >= 65 && asciiCode <= 72:
        sampleIds = this.props.samples.map(sample => {
          return (
            "&yep1f" +
            sample.sampleId +
            "=full&yep1p" +
            sample.sampleId +
            "=dense&yep1r" +
            sample.sampleId +
            "=full"
          );
        });
        // console.log(sampleTracks);
        console.log(sampleTracks + sampleIds.join(""));
        window.open(sampleTracks + sampleIds.join(""), "_blank");
        break;

      // I-Q
      case asciiCode >= 73 && asciiCode <= 81:
        sampleIds = this.props.samples.map(sample => {
          return (
            "&yep2f" +
            sample.sampleId +
            "=full&yep2p" +
            sample.sampleId +
            "=dense&yep2r" +
            sample.sampleId +
            "=full"
          );
        });
        // console.log(sampleTracks);
        console.log(sampleTracks + sampleIds.join(""));
        window.open(sampleTracks + sampleIds.join(""), "_blank");
        break;

      // R-S
      case asciiCode >= 82 && asciiCode <= 83:
        sampleIds = this.props.samples.map(sample => {
          return (
            "&yep3f" +
            sample.sampleId +
            "=full&yep3p" +
            sample.sampleId +
            "=dense&yep3r" +
            sample.sampleId +
            "=full"
          );
        });
        // console.log(sampleTracks);
        console.log(sampleTracks + sampleIds.join(""));
        window.open(sampleTracks + sampleIds.join(""), "_blank");
        break;

      // T-Z
      case asciiCode >= 84 && asciiCode <= 90:
        sampleIds = this.props.samples.map(sample => {
          return (
            "&yep4f" +
            sample.sampleId +
            "=full&yep4p" +
            sample.sampleId +
            "=dense&yep4r" +
            sample.sampleId +
            "=full"
          );
        });
        // console.log(sampleTracks);
        console.log(sampleTracks + sampleIds.join(""));
        window.open(sampleTracks + sampleIds.join(""), "_blank");
        break;
      default:
        break;
    }

    // console.log("handleTrackHubClick() from Sample.js =>  Navigate to Track Hub");
  };

  render() {
    const { classes } = this.props;

    return (
      <Paper square elevation={0} className={classes.appBar}>
        <DialogActions>
          <Button
            size="small"
            color="primary"
            onClick={this.handleTrackHubClick}
            className={classes.closeButton}
          >
            <TrackHubIcon className={classes.leftIcon} />
            visualize all
          </Button>
        </DialogActions>
        {/* Cart Items in a table format */}
        <CardContent>
          <Table className={classes.table}>
            <TableHead className={classes.tableHeader}>
              <TableRow className={classes.borderLine}>
                <TableCell align="left" className={classes.borderLine}>
                  Protein Name
                </TableCell>
                <TableCell align="left" className={classes.borderLine}>
                  Sample ID
                </TableCell>
                <TableCell align="left" className={classes.borderLine}>
                  Assay
                </TableCell>
                <TableCell align="left" className={classes.borderLine}>
                  Condition
                </TableCell>
                <TableCell align="center" className={classes.borderLine}>
                  Track Loader
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.props.samples.map(item => {
                return (
                  <TableRow key={item._id} className={classes.borderLine}>
                    <TableCell align="left" className={classes.borderLine}>
                      {item.standardGeneName}
                    </TableCell>
                    <TableCell align="left" className={classes.borderLine}>
                      {item.sampleId}
                    </TableCell>
                    <TableCell align="left" className={classes.borderLine}>
                      {item.assayType}
                    </TableCell>
                    <TableCell align="left" className={classes.borderLine}>
                      {item.growthMedia} / {item.treatments}
                    </TableCell>
                    <TableCell align="center" className={classes.borderLine}>
                      <Button
                        size="small"
                        color="primary"
                        onClick={this.singleTrackLoader(item.sampleId)}
                      >
                        <LaunchIcon className={classes.leftIcon} />
                        visualize
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </CardContent>
      </Paper>
    );
  }
}

TrackLoader.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(TrackLoader);
