import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";

import DataContext from "./DataContext";
import HelpStepper2 from "./HelpStepper2";

const styles = {
  root: {
    height: "100%",
    display: "flex",
    flexDirection: "column"
  }
};

class Help extends React.Component {
  static contextType = DataContext;

  render() {
    const { classes } = this.props;
    // Setting the title of the browser tab
    document.title = "HELP | YEP";

    return (
      <div className={classes.root}>
        <HelpStepper2 />
      </div>
    );
  }
}

Help.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withRouter(withStyles(styles)(Help));
