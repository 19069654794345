import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { Paper, Badge, Divider } from "@material-ui/core";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`
  };
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper
  },
  treatmentBadge: {
    marginRight: 20
  }
}));

export default function ScrollableTabsButtonAuto() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const label1 = (
    <Typography variant="body1">
      <Badge
        color="primary"
        className={classes.treatmentBadge}
        badgeContent={" "}
      />
      SampleID
    </Typography>
  );

  const label2 = (
    <Typography variant="body1">
      <Badge
        color="secondary"
        className={classes.treatmentBadge}
        badgeContent={" "}
      />
      SampleID
    </Typography>
  );

  const label3 = (
    <Typography variant="body1">
      <Badge
        style={{
          background: "#388e3c",
          padding: 11,
          borderRadius: 20,
          marginRight: 10
        }}
        badgeContent={" "}
      />
      SampleID
    </Typography>
  );

  //   const label4 = (
  //     <Typography variant="body1">
  //       <Badge
  //         style={{
  //           background: "#9c27b0",
  //           padding: 11,
  //           borderRadius: 20,
  //           marginRight: 10
  //         }}
  //         badgeContent={" "}
  //       />
  //       SampleID
  //     </Typography>
  //   );

  return (
    <div className={classes.root}>
      <Paper position="static" color="default" square>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          <Tab label={label1} {...a11yProps(0)} />
          <Tab label={label2} {...a11yProps(1)} />
          <Tab label={label3} {...a11yProps(2)} />
          {/* <Tab label={label4} {...a11yProps(3)} /> */}
        </Tabs>
        <Divider />
      </Paper>
      <Paper square>
        <TabPanel value={value} index={0}>
          <strong>Blue</strong> dot in the tab signifies that the sample is a{" "}
          <strong>YPD</strong> sample. <br />
          <strong>Red</strong> dot in the tab signifies that the sample is a{" "}
          <strong>Heat Shock</strong> sample <br />
          <strong>Green</strong> dot in the tab signifies that the sample is an{" "}
          <strong>Oxidative Stress</strong> sample
        </TabPanel>
        <TabPanel value={value} index={1}>
          <strong>Blue</strong> dot in the tab signifies that the sample is a{" "}
          <strong>YPD</strong> sample. <br />
          <strong>Red</strong> dot in the tab signifies that the sample is a{" "}
          <strong>Heat Shock</strong> sample <br />
          <strong>Green</strong> dot in the tab signifies that the sample is an{" "}
          <strong>Oxidative Stress</strong> sample
        </TabPanel>
        <TabPanel value={value} index={2}>
          <strong>Blue</strong> dot in the tab signifies that the sample is a{" "}
          <strong>YPD</strong> sample. <br />
          <strong>Red</strong> dot in the tab signifies that the sample is a{" "}
          <strong>Heat Shock</strong> sample <br />
          <strong>Green</strong> dot in the tab signifies that the sample is an{" "}
          <strong>Oxidative Stress</strong> sample
        </TabPanel>
        {/* <TabPanel value={value} index={3}>
          Purple dot in the tab represents <strong>Heat Shock</strong>Other
          Stress sample
        </TabPanel> */}
      </Paper>
    </div>
  );
}
